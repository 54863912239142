.scroll-button {
    position: fixed;
    bottom: 20px;  
    right: 20px;  
    background-color: #378805; 
    color: white; 
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    opacity: 0; 
    transition: opacity 0.3s, background-color 0.3s; 
  }
  
  .scroll-button.visible {
    opacity: 1;
  }
  
  .scroll-button:hover {
    background-color: #2a5d00; 
  }
  